import React from 'react';
import MuiCircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

/**
 *
 * Loader uses the MUI CircularProgress
 * and it allows to use the contrastText
 */

type Props = CircularProgressProps & {
  useContrastText?: boolean;
};

const Loader = ({ color, sx, useContrastText, ...props }: Props) => {
  return (
    <MuiCircularProgress
      {...props}
      color={color}
      sx={[
        ...(Array.isArray(sx) ? sx : [sx]),
        theme => ({
          ...(color &&
            useContrastText === true &&
            theme.palette[color]?.contrastText && {
              color: theme.palette[color]?.contrastText,
            }),
        }),
      ]}
    />
  );
};

export default Loader;
