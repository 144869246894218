import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { transformAddress } from '@docavenue/core';
import { getAddresses } from './useAddresses';

const useTransformedAddress = (fullAddress?: string) => {
  const dispatch = useDispatch();

  const getAddressTransformed = async () => {
    if (!fullAddress) return null;

    const searchAddressResult = await getAddresses(dispatch, fullAddress);

    const matchedAddress = searchAddressResult?.find(
      address => address.properties.label === fullAddress,
    );

    return matchedAddress ? transformAddress(matchedAddress) : null;
  };

  return useQuery({
    queryKey: ['transformedProfileAddress', fullAddress],
    queryFn: getAddressTransformed,
    refetchOnMount: false,
  });
};

export default useTransformedAddress;
