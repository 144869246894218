import * as React from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
  children?: React.ReactNode;
  variant?: 'float' | 'card';
  color?: 'blue' | 'lightblue' | 'grey';
  tabIndex?: number;
  testid?: string;
}

const Paper: React.FC<Props> = ({
  className,
  variant,
  children,
  color,
  testid,
}) => {
  const _className = clsx(
    className,
    'paper percy-hidden',
    variant && `${variant}`,
    color && `${color}`,
  );

  return (
    <div data-testid={testid} className={_className}>
      {children}
    </div>
  );
};

export default Paper;
