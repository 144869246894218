import React, { Fragment, ReactElement, ReactNode } from 'react';
import Downshift, { DownshiftProps } from 'downshift';
import { cn } from '@kiiwi/ui';
import Paper from '../../atoms/Paper/Paper';
import Input from '../../atoms/Input/Input';
import Suggestion from './Suggestion';
import Loader from '../../atoms/Loader/Loader';
import UserLocationButtons from '../UserLocationButtons/UserLocationButtons';
import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';

let inputId = 0;

type Props = {
  selectedItemIndex?: number;
  suggestions: Array<any>;
  onClear?: Function;
  onSearch: Function;
  onChange: (selectedItem: any) => void;
  label?: string;
  hideLabel?: boolean;
  placeholder?: string;
  initialInputValue: any;
  renderItem: Function;
  focusInput?: boolean;
  breakCondition?: Function;
  inputProps?: any;
  noAutoHighlight?: boolean;
  allowClear?: boolean;
  renderRightIcon?: (args: { rightIcon: React.ReactNode }) => React.ReactNode;
  isLoading: boolean;
  topElement?: ReactNode;
  bottomElement?: ReactNode;
  maxLength?: number;
  showQuickAddress?: boolean;
  inputContainerClassName?: string;
  inputClassName?: string;
  dropdownClassName?: string;
  dropdownContainerClassName?: string;
  leftComponent?: ReactElement;
};

const IntegrationDownshift = ({
  selectedItemIndex,
  suggestions,
  initialInputValue,
  focusInput,
  onClear,
  onSearch,
  onChange = () => {},
  placeholder,
  renderItem,
  breakCondition,
  inputProps,
  noAutoHighlight,
  allowClear,
  renderRightIcon = ({ rightIcon }) => rightIcon,
  isLoading,
  topElement = null,
  bottomElement = null,
  label,
  hideLabel = false,
  maxLength,
  showQuickAddress,
  inputContainerClassName,
  inputClassName,
  dropdownClassName,
  dropdownContainerClassName,
  leftComponent,
  ...props
}: Props) => {
  const downshiftRef = React.useRef<React.Component<DownshiftProps<any>>>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const updateSelectedIndex = React.useCallback(
    (itemIndex, items, downshiftDOMRef) => {
      if (
        !Number.isInteger(itemIndex) ||
        itemIndex < 0 ||
        items.length <= itemIndex
      ) {
        downshiftDOMRef.current?.setState({ selectedItem: null });
        return;
      }
      const {
        state: { selectedItem },
        selectItem,
      } = downshiftDOMRef.current;
      if ((selectedItem || {}).id !== items[itemIndex].id) {
        return selectItem(items[itemIndex]);
      }
    },
    [],
  );

  const handleClear = React.useCallback(() => {
    // @ts-ignore
    downshiftRef.current?.clearSelection();
    onClear?.();
  }, []);

  React.useEffect(() => {
    updateSelectedIndex(selectedItemIndex, suggestions, downshiftRef);
  }, [suggestions, selectedItemIndex]);

  React.useEffect(() => {
    if (focusInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusInput]);

  React.useEffect(() => {
    if (noAutoHighlight) {
      downshiftRef.current?.setState({ highlightedIndex: null });
    }
  }, [noAutoHighlight]);

  return (
    <Downshift
      {...props}
      ref={downshiftRef}
      id="downshift-simple"
      itemToString={item => (item ? item.label : '')}
      onChange={selectedItem => {
        if (selectedItem?.shortName === 'suggestion_tlc') {
          handleClear();
        }
        onChange(selectedItem);
      }}
      initialInputValue={initialInputValue}
      defaultHighlightedIndex={0}
      inputId={`downshift_input_${inputId++}`}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getLabelProps,
        highlightedIndex,
        isOpen,
        selectedItem,
        openMenu,
        setState,
        closeMenu,
      }) => (
        <div className="flex flex-row items-center">
          {label && (
            <label {...getLabelProps()} hidden={hideLabel}>
              {label}
            </label>
          )}
          <Input
            {...getInputProps({
              ...(inputProps ?? {}),
              onChange: e => {
                onSearch(e.target.value);
              },
              onFocus: () => openMenu(),
            })}
            placeholder={placeholder}
            ref={inputRef}
            title={label}
            maxLength={maxLength}
            inputContainerClassName={inputContainerClassName}
            inputClassName={cn('text-ellipsis', inputClassName)}
            leftComponent={leftComponent}
          />

          <div className={dropdownContainerClassName} {...getMenuProps()}>
            {isOpen && !isLoading ? (
              <Paper
                className={cn('dropdown-autocomplete', dropdownClassName)}
                variant="float"
                tabIndex={0}
                testid="search-result-list"
              >
                {showQuickAddress && (
                  <UserLocationButtons
                    onSelectItem={item => {
                      // selectItem triggers a warning in the console
                      // selectItem(item);
                      setState({ selectedItem: item, inputValue: item.label });
                      closeMenu();
                    }}
                    onSearch={(value: string) => {
                      onSearch(value);
                      setState({ inputValue: value });
                    }}
                  />
                )}
                {topElement}
                {suggestions.map((suggestion, index) => (
                  <Fragment key={suggestion.id}>
                    {breakCondition &&
                      breakCondition(
                        suggestion,
                        suggestions[index - 1] ?? {},
                        index,
                      ) && <hr />}
                    <Suggestion
                      suggestion={suggestion}
                      index={index}
                      itemProps={getItemProps({ item: suggestion })}
                      highlightedIndex={highlightedIndex}
                      selectedItem={selectedItem}
                      renderItem={renderItem}
                    />
                  </Fragment>
                ))}
                {bottomElement}
              </Paper>
            ) : null}
          </div>
          <div className="absolute right-4">
            {renderRightIcon({
              rightIcon: (
                <>
                  {allowClear && !isLoading && getInputProps().value !== '' && (
                    <FontAwesomeIcon
                      name="xmark:regular"
                      onClick={handleClear}
                      className="size-4 cursor-pointer text-content-primary hover:opacity-80"
                    />
                  )}
                  {isLoading && <Loader size={20} />}
                </>
              ),
            })}
          </div>
        </div>
      )}
    </Downshift>
  );
};

export default IntegrationDownshift;
