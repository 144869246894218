import React from 'react';
import { Divider } from '@mui/material';

// !important used to override old CSS on mobile
const MenuItemDivider = () => {
  return (
    <Divider
      sx={theme => ({
        bgcolor: `${theme.palette.background.lightGrey}!important`,
        height: '1px !important',
        margin: `${theme.spacing(1, 2)}!important`,
        border: '0 !important', // override for mobile
      })}
    />
  );
};

export default MenuItemDivider;
