import * as React from 'react';
import { cn } from '@kiiwi/ui';

type Props = {
  className?: string;
  placeholder?: string;
  variant?: string;
  leftComponent?: React.ReactElement;
  iconComponent?: React.ReactElement;
  onIconClick?: Function;
  inputContainerClassName?: string;
  inputClassName?: string;
} & JSX.IntrinsicElements['input'];

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      placeholder,
      variant,
      iconComponent,
      onIconClick,
      inputContainerClassName,
      inputClassName,
      leftComponent,
      ...props
    }: Props,
    ref,
  ) => {
    const renderIcon = () =>
      iconComponent &&
      React.cloneElement(iconComponent, {
        className: 'btn icon',
        onClick: onIconClick,
        width: 32,
        height: 32,
        fill: '',
      });
    return (
      <div
        className={cn(
          'input-wrapper',
          className,
          variant && `input--${variant}`,
          inputContainerClassName,
        )}
      >
        {leftComponent && leftComponent}
        <input
          className={cn(
            'input-field',
            inputClassName ? `w-full ${inputClassName}` : '',
          )}
          placeholder={placeholder}
          ref={ref}
          {...props}
        />
        {variant === 'with-icon' && renderIcon()}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
